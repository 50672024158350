/**
 * GlobalCollection
 *
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {Global} from '@/model/resource/Global'

/* TODO: review generated class */
@HttpExclude()
export class GlobalCollection extends PageCollection<Global> {
  constructor() {
    super(Global)
  }

  resource?: IGlobalCollectionResourcesHolder

  @RequestExpose() minDappImageDimension: number | null = null
  @RequestExpose() maxDappImageDimension: number | null = null
  @RequestExpose() minBoostedDappMaxsize: number | null = null
  @RequestExpose() maxBoostedDappMaxsize: number | null = null
  @RequestExpose() minMinDappTransactionCountForTrending: number | null = null
  @RequestExpose() maxMinDappTransactionCountForTrending: number | null = null

  queryAsPage() {
    return this.listGlobal()
  }

  async listGlobal() {
    return await Request.get(`/client/global`, {params: this.params})
      .name('listGlobal')
      .as(this)
      .getResponse()
  }

  async listExportGlobal() {
    return await Request.get(`/client/global/export`, {params: this.params})
      .name('listExportGlobal')
      .as(this)
      .getResponse()
  }
}

export interface IGlobalCollectionResourcesHolder {}
