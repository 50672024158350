import {WalletMutation} from '@/enums/WalletMutation'
import {GenericWalletAdapter} from '@/model/wallets/adapters/GenericWalletAdapter'
import {WalletAdapterContext} from '@/store/modules/WalletAdapterModule'
import {WalletPlatform} from '@/enums/WalletPlatform'
import {$} from '@/facade'
import {PopulateSdkStatusParams} from '@/model/wallets/types/WalletTypes'
import {InvocationBuilderResult} from '@/model/wallets/invocationsBuilder/GenericInvocationBuilder'
import {
  SdkNotInitializedError,
  RejectedByUser,
  BadInvocationRequest,
} from '@/model/wallets/types/WalletErrors'

export class NeolineAdapter implements GenericWalletAdapter {
  private sdkN3: any = null

  // Generic Wallets Implementation
  async init(context: WalletAdapterContext): Promise<void> {
    if (typeof window !== 'undefined') {
      window.addEventListener('NEOLine.N3.EVENT.READY', () =>
        this.updateN3SDK(context)
      )
      window.addEventListener('NEOLine.NEO.EVENT.ACCOUNT_CHANGED', () => {
        if (context.state.connectedWalletPlatform === WalletPlatform.NEOLINE) {
          $.walletAdapter.disconnect()
        }
      })
    }
  }

  async connect(context: WalletAdapterContext) {
    if (!this.sdkN3) throw SdkNotInitializedError(WalletPlatform.NEOLINE)
    const {address} = await this.sdkN3.getAccount()
    if (address) {
      context.commit(WalletMutation.POPULATE_ADDRESS, address)
    } else throw RejectedByUser
  }

  async disconnect(): Promise<void> {
    return // Neoline doesn't have support to this
  }

  isInstalled(): boolean {
    return typeof (window as any).NEOLineN3 !== 'undefined' && this.sdkN3
  }

  async invoke(multiInvoke: InvocationBuilderResult): Promise<string> {
    if (!this.sdkN3) throw SdkNotInitializedError(WalletPlatform.NEOLINE)
    try {
      const invokeOutput = await this.sdkN3.invokeMultiple(multiInvoke)
      if (!invokeOutput && !invokeOutput.txid) throw BadInvocationRequest
      return invokeOutput.txid
    } catch (e) {
      console.error(e)
    }
    return ''
  }

  // Neoline only implementation

  private updateSdkStatus(context: WalletAdapterContext, success: boolean) {
    if (success) {
      context.commit(WalletMutation.POPULATE_SDK_STATUS, {
        wallet: WalletPlatform.NEOLINE,
        status: 'started',
      } as PopulateSdkStatusParams)
    } else {
      context.commit(WalletMutation.POPULATE_SDK_STATUS, {
        wallet: WalletPlatform.NEOLINE,
        status: 'error',
      } as PopulateSdkStatusParams)
    }
  }

  private updateN3SDK(context: WalletAdapterContext) {
    try {
      const neolineN3 = (window as any).NEOLineN3
      if (typeof neolineN3 !== 'undefined') {
        this.sdkN3 = new neolineN3.Init()
        this.updateSdkStatus(context, true)
      } else {
        throw SdkNotInitializedError(WalletPlatform.NEOLINE)
      }
    } catch {
      this.updateSdkStatus(context, false)
    }
  }
}
