import {RootState, WalletAdapterState} from '@/types/store'
import {ActionContext, Module} from 'vuex'
import {AccessorHandler} from '@simpli/vuex-typescript'
import {WalletPlatform} from '@/enums/WalletPlatform'
import {WalletMutation} from '@/enums/WalletMutation'
import {$} from '@/facade'
import {NeolineAdapter} from '@/model/wallets/adapters/NeolineAdapter'
import {NeonWalletAdapter} from '@/model/wallets/adapters/NeonWalletAdapter'
import {NeolineInvocationBuilder} from '@/model/wallets/invocationsBuilder/NeolineInvocationBuilder'
import {NeonWalletInvocationBuilder} from '@/model/wallets/invocationsBuilder/NeonWalletInvocationBuilder'
import {cloneDeep} from 'lodash'
import {
  InvokeParams,
  LastWalletConnected,
  PopulateSdkStatusParams,
  WalletsImplementation,
} from '@/model/wallets/types/WalletTypes'
import {
  InvocationDoesNotExists,
  RejectedByUser,
  UnimplementedWallet,
  WalletNotConnected,
} from '@/model/wallets/types/WalletErrors'

export type WalletAdapterContext = ActionContext<WalletAdapterState, RootState>

const walletsImplementation: WalletsImplementation = {
  [WalletPlatform.NEON]: {
    adapter: new NeonWalletAdapter(),
    invocationBuilder: new NeonWalletInvocationBuilder(),
  },
  [WalletPlatform.NEOLINE]: {
    adapter: new NeolineAdapter(),
    invocationBuilder: new NeolineInvocationBuilder(),
  },
}

const lastWalletLocalStorageId: string = 'lastWalletConnected'

@AccessorHandler
export class WalletAdapterModule
  implements Module<WalletAdapterState, RootState> {
  namespaced = true

  state: WalletAdapterState = {
    walletsStatus: {
      [WalletPlatform.NEON]: null,
      [WalletPlatform.NEOLINE]: null,
    },
    lastWalletConnected: null,
    connectedWalletPlatform: null,
    address: null,
    uri: null,
  }

  getters = {
    lastWalletConnected: (state: WalletAdapterState) =>
      state.lastWalletConnected,
    connectedWalletPlatform: (state: WalletAdapterState) =>
      state.connectedWalletPlatform,
    address: (state: WalletAdapterState) => state.address,
    uri: (state: WalletAdapterState) => state.uri,
  }

  actions = {
    async init(context: WalletAdapterContext) {
      const initPromises = Object.values(walletsImplementation).map(wallet => {
        if (wallet) {
          return wallet.adapter.init(context)
        }

        return Promise.resolve() // Return a resolved promise
      })

      await Promise.all(initPromises)

      if (
        typeof localStorage !== 'undefined' &&
        !context.state.address &&
        localStorage.getItem(lastWalletLocalStorageId)?.trim()
      ) {
        const storage: string | null = localStorage.getItem(
          lastWalletLocalStorageId
        )

        if (storage?.trim()) {
          const lastWallet = JSON.parse(storage.trim()) as LastWalletConnected

          if (lastWallet) {
            context.commit(WalletMutation.POPULATE_ADDRESS, lastWallet.address)
            context.commit(
              WalletMutation.POPULATE_CONNECTED_WALLET_TYPE,
              lastWallet.walletPlatform
            )
            context.commit(
              WalletMutation.POPULATE_LAST_WALLET_CONNECTED,
              lastWallet
            )
          }
        }
      }
    },

    async connect(
      context: WalletAdapterContext,
      walletPlatform: WalletPlatform
    ) {
      if (context.state.connectedWalletPlatform) {
        return false
      }

      const wallet = walletsImplementation[walletPlatform]
      if (!wallet) throw UnimplementedWallet

      await wallet.adapter.connect(context)

      if (context.state.address) {
        /** We update Last Wallet Connected because NeoLine Hooks does
         *  not work properly, so we need to know if the user has connected
         *  before and not disconnected.
         */
        context.commit(WalletMutation.POPULATE_LAST_WALLET_CONNECTED, {
          address: context.state.address,
          walletPlatform: walletPlatform,
        } as LastWalletConnected)

        if (typeof localStorage !== 'undefined') {
          localStorage.setItem(
            lastWalletLocalStorageId,
            JSON.stringify({
              address: context.state.address,
              walletPlatform: walletPlatform,
            })
          )
        }

        // Close connect wallet modal
        $.modal?.close('connectWalletModal')

        // Update URI
        context.commit(WalletMutation.POPULATE_URI, null)

        // Update connected wallet type
        context.commit(
          WalletMutation.POPULATE_CONNECTED_WALLET_TYPE,
          walletPlatform
        )

        $.toast.success('system.success.walletConnected')
      } else throw RejectedByUser
    },

    async disconnect(context: WalletAdapterContext) {
      if (!context.state.connectedWalletPlatform) return false

      const wallet =
        walletsImplementation[context.state.connectedWalletPlatform]

      if (!wallet) {
        return false
      }

      await wallet.adapter.disconnect()

      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(lastWalletLocalStorageId)
      }

      context.commit(WalletMutation.POPULATE_ADDRESS, null)
      context.commit(WalletMutation.POPULATE_CONNECTED_WALLET_TYPE, null)
      context.commit(WalletMutation.POPULATE_LAST_WALLET_CONNECTED, null)
    },

    async canUseWallet(
      context: WalletAdapterContext,
      walletPlatform: WalletPlatform
    ) {
      if (!walletPlatform) return false

      const wallet = walletsImplementation[walletPlatform]
      const walletStatus = context.state.walletsStatus[walletPlatform]

      if (!wallet || !walletStatus) {
        return false
      }

      return wallet.adapter.isInstalled() && walletStatus === 'started'
    },

    async invoke(context: WalletAdapterContext, multiInvoke: InvokeParams) {
      if (!context.state.connectedWalletPlatform) throw WalletNotConnected

      if (!multiInvoke || !multiInvoke.method || !multiInvoke.params)
        throw InvocationDoesNotExists

      const wallet =
        walletsImplementation[context.state.connectedWalletPlatform]

      const walletStatus =
        context.state.walletsStatus[context.state.connectedWalletPlatform]

      if (!wallet || !wallet.invocationBuilder || walletStatus !== 'started')
        throw UnimplementedWallet

      const method = wallet.invocationBuilder[multiInvoke.method].bind(
        wallet.invocationBuilder
      )
      if (!method || typeof method !== 'function') throw InvocationDoesNotExists

      return (await wallet.adapter.invoke(
        method(multiInvoke.params as any)
      )) as string
    },

    async executeWithConnectedWallet(
      context: WalletAdapterContext,
      actionToDoAfterConnect: () => Promise<void> | void
    ) {
      const walletIsConnected: WalletPlatform | null =
        context.state.connectedWalletPlatform

      if (walletIsConnected != null) {
        await actionToDoAfterConnect()
        return
      }

      $?.modal.open('connectWalletModal', actionToDoAfterConnect)
    },
  }

  mutations = {
    POPULATE_LAST_WALLET_CONNECTED(
      state: WalletAdapterState,
      lastWalletConnected: LastWalletConnected | null
    ) {
      state.lastWalletConnected = lastWalletConnected
    },
    POPULATE_CONNECTED_WALLET_TYPE(
      state: WalletAdapterState,
      connectedWalletPlatform: WalletPlatform | null
    ) {
      state.connectedWalletPlatform = connectedWalletPlatform
    },
    POPULATE_SDK_STATUS(
      state: WalletAdapterState,
      params: PopulateSdkStatusParams
    ) {
      state.walletsStatus = cloneDeep({
        ...state.walletsStatus,
        [params.wallet]: params.status,
      })
    },
    POPULATE_URI(state: WalletAdapterState, uri: string | null) {
      state.uri = uri
    },
    POPULATE_ADDRESS(state: WalletAdapterState, address: string | null) {
      state.address = address
    },
  }
}
