const axios = require('axios').default

const routes = [
  {
    path: '/sign-in',
    name: 'signIn',
    component: () => import('./views/auth/SignInView.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/password/recover',
    name: 'recoverPasswordByMail',
    component: () => import('./views/auth/RecoverPasswordByMailView.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/app-detailed/:id',
    name: 'appDetailedView',
    component: () => import('./views/AppDetailedView.vue'),
    meta: {
      sitemap: {
        slugs: getAppSlugs,
      },
    },
  },
  {
    path: '/dapp-detailed/:id',
    name: 'dappDetailedView',
    component: () => import('./views/DappDetailedView.vue'),
    meta: {
      sitemap: {
        slugs: getDappSlugs,
      },
    },
  },
  {
    path: '/dapps-overview',
    name: 'dappsOverview',
    component: () => import('./views/DappsOverviewView.vue'),
  },
  {
    path: '/dapps',
    redirect: '/dapps-overview',
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/apps-overview',
    name: 'appsOverview',
    component: () => import('./views/AppsOverviewView.vue'),
  },
  {
    path: '/apps',
    redirect: '/apps-overview',
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: () => import('./views/ContractsView.vue'),
  },
  {
    path: '/wallets',
    name: 'wallets',
    component: () => import('./views/WalletsView.vue'),
  },
  {
    path: '/explorers',
    name: 'explorers',
    component: () => import('./views/ExplorersView.vue'),
  },
  {
    path: '/exchanges',
    name: 'exchanges',
    component: () => import('./views/ExchangesView.vue'),
  },
  {
    path: '/tokens',
    name: 'tokens',
    component: () => import('./views/TokensPriceView.vue'),
  },
  {
    path: '/nfts',
    name: 'nfts',
    component: () => import('./views/NFTCollectionView.vue'),
  },
  {
    path: '/community-development',
    name: 'communityActivity',
    component: () => import('./views/CaView.vue'),
  },
  {
    path: '/password/reset/:hash',
    name: 'resetPassword',
    component: () => import('./views/auth/ResetPasswordView.vue'),
    props: true,
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/gasbot',
    name: 'gasbot',
    component: () => import('./views/GasBotView.vue'),
  },
  {
    path: '/terms-and-conditions',
    name: 'termsAndConditions',
    component: () => import('./views/TermsAndConditionsView.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('./views/PrivacyPolicyView.vue'),
  },
  {
    path: '/gas-calculator',
    name: 'advancedGasCalculator',
    component: () => import('./views/GasCalculatorView.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import('./views/HomeView.vue'),
  },
  {
    path: '/my-wallet',
    name: 'myWallet',
    component: () => import('./views/MyWalletView.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/dashboard',
    component: () => import('./views/layouts/DefaultLayout.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('./views/DashboardView.vue'),
      },

      {
        path: '/user/list',
        component: () => import('./views/list/ListUserView.vue'),
      },
      {
        path: '/user/new',
        component: () => import('./views/persist/PersistUserView.vue'),
      },
      {
        path: '/user/edit/:id',
        name: 'editUser',
        component: () => import('./views/persist/PersistUserView.vue'),
        props: true,
      },

      {
        path: '/category/list',
        component: () => import('./views/list/ListCategoryView.vue'),
      },
      {
        path: '/category/new',
        component: () => import('./views/persist/PersistCategoryView.vue'),
      },
      {
        path: '/category/edit/:id',
        name: 'editCategory',
        component: () => import('./views/persist/PersistCategoryView.vue'),
        props: true,
      },
      {
        path: '/token-priced/list',
        component: () => import('./views/list/ListTokenPricedView.vue'),
      },
      {
        path: '/token/list',
        component: () => import('./views/list/ListTokenView.vue'),
      },
      {
        path: '/token/new',
        component: () => import('./views/persist/PersistTokenView.vue'),
      },
      {
        path: '/token/edit/:id',
        name: 'editToken',
        component: () => import('./views/persist/PersistTokenView.vue'),
        props: true,
      },

      {
        path: '/supportedToken/list',
        component: () => import('./views/list/ListSupportedTokenView.vue'),
      },
      {
        path: '/supportedToken/new',
        component: () =>
          import('./views/persist/PersistSupportedTokenView.vue'),
      },
      {
        path: '/supportedToken/edit/:id',
        name: 'editSupportedToken',
        component: () =>
          import('./views/persist/PersistSupportedTokenView.vue'),
        props: true,
      },

      {
        path: '/supported-server/list',
        component: () => import('./views/list/ListSupportedServerView.vue'),
      },
      {
        path: '/supported-server/new',
        component: () =>
          import('./views/persist/PersistSupportedServerView.vue'),
      },
      {
        path: '/supported-server/edit/:id',
        name: 'editSupportedServer',
        component: () =>
          import('./views/persist/PersistSupportedServerView.vue'),
        props: true,
      },

      {
        path: '/developer-aid',
        component: () => import('./views/persist/PersistDeveloperAidView.vue'),
      },

      {
        path: '/global',
        component: () => import('./views/persist/PersistGlobalView.vue'),
      },
      {
        path: '/boosted-dapp/list',
        component: () => import('./views/list/ListBoostedDappView.vue'),
      },
      {
        path: '/boosted-dapp/new',
        component: () => import('./views/persist/PersistBoostedDappView.vue'),
      },
      {
        path: '/boosted-dapp/edit/:id',
        name: 'editBoostedDapp',
        component: () => import('./views/persist/PersistBoostedDappView.vue'),
        props: true,
      },

      {
        path: '/banner/list',
        component: () => import('./views/list/ListBannerView.vue'),
      },
      {
        path: '/banner/new',
        component: () => import('./views/persist/PersistBannerView.vue'),
      },
      {
        path: '/banner/edit/:id',
        name: 'editBanner',
        component: () => import('./views/persist/PersistBannerView.vue'),
        props: true,
      },

      {
        path: '/wallet/list',
        component: () => import('./views/list/ListWalletView.vue'),
      },
      {
        path: '/wallet/new',
        component: () => import('./views/persist/PersistWalletView.vue'),
      },
      {
        path: '/wallet/edit/:id',
        name: 'editWallet',
        component: () => import('./views/persist/PersistWalletView.vue'),
        props: true,
      },

      {
        path: '/explorer/list',
        component: () => import('./views/list/ListExplorerView.vue'),
      },
      {
        path: '/explorer/new',
        component: () => import('./views/persist/PersistExplorerView.vue'),
      },
      {
        path: '/explorer/edit/:id',
        name: 'editExplorer',
        component: () => import('./views/persist/PersistExplorerView.vue'),
        props: true,
      },

      {
        path: '/dapp-social/list',
        component: () => import('./views/list/ListDappSocialView.vue'),
      },
      {
        path: '/dapp-social/new',
        component: () => import('./views/persist/PersistDappSocialView.vue'),
      },
      {
        path: '/dapp-social/edit/:id1/:id2',
        name: 'editDappSocial',
        component: () => import('./views/persist/PersistDappSocialView.vue'),
        props: true,
      },

      {
        path: '/nnt-tag/list',
        component: () => import('./views/list/ListNntTagView.vue'),
      },
      {
        path: '/nnt-tag/new',
        component: () => import('./views/persist/PersistNntTagView.vue'),
      },
      {
        path: '/nnt-tag/edit/:id',
        name: 'editNntTag',
        component: () => import('./views/persist/PersistNntTagView.vue'),
        props: true,
      },

      {
        path: '/social-type/list',
        component: () => import('./views/list/ListSocialTypeView.vue'),
      },
      {
        path: '/social-type/new',
        component: () => import('./views/persist/PersistSocialTypeView.vue'),
      },
      {
        path: '/social-type/edit/:id',
        name: 'editSocialType',
        component: () => import('./views/persist/PersistSocialTypeView.vue'),
        props: true,
      },

      {
        path: '/dapp/list',
        component: () => import('./views/list/ListDappView.vue'),
      },
      {
        path: '/dapp/new',
        component: () => import('./views/persist/PersistDappView.vue'),
      },
      {
        path: '/dapp/edit/:id',
        name: 'editDapp',
        component: () => import('./views/persist/PersistDappView.vue'),
        props: true,
      },

      {
        path: '/dapp-connection/list',
        component: () => import('./views/list/ListDappConnectionView.vue'),
      },
      {
        path: '/dapp-connection/new',
        component: () =>
          import('./views/persist/PersistDappConnectionView.vue'),
      },
      {
        path: '/dapp-connection/edit/:id',
        name: 'editDappConnection',
        component: () =>
          import('./views/persist/PersistDappConnectionView.vue'),
        props: true,
      },

      {
        path: '/contract/list',
        component: () => import('./views/list/ListContractView.vue'),
      },
      {
        path: '/contract/new',
        component: () => import('./views/persist/PersistContractView.vue'),
      },
      {
        path: '/contract/edit/:id',
        name: 'editContract',
        component: () => import('./views/persist/PersistContractView.vue'),
        props: true,
      },
      {
        path: '/contract/nep17/edit/:id',
        name: 'editContractNep17',
        component: () => import('./views/persist/PersistContractNep17View.vue'),
        props: true,
      },

      {
        path: '/blockchain-daily-cache/list',
        component: () =>
          import('./views/list/ListBlockchainDailyCacheView.vue'),
      },
      {
        path: '/blockchain-daily-cache/new',
        component: () =>
          import('./views/persist/PersistBlockchainDailyCacheView.vue'),
      },
      {
        path: '/blockchain-daily-cache/edit/:id',
        name: 'editBlockchainDailyCache',
        component: () =>
          import('./views/persist/PersistBlockchainDailyCacheView.vue'),
        props: true,
      },

      {
        path: '/blockchain-version/list',
        component: () => import('./views/list/ListBlockchainVersionView.vue'),
      },
      {
        path: '/blockchain-version/new',
        component: () =>
          import('./views/persist/PersistBlockchainVersionView.vue'),
      },
      {
        path: '/blockchain-version/edit/:id',
        name: 'editBlockchainVersion',
        component: () =>
          import('./views/persist/PersistBlockchainVersionView.vue'),
        props: true,
      },

      {
        path: '/private-category/list',
        component: () => import('./views/list/ListPrivateCategoryView.vue'),
      },
      {
        path: '/private-category/new',
        component: () =>
          import('./views/persist/PersistPrivateCategoryView.vue'),
      },
      {
        path: '/private-category/edit/:id',
        name: 'editPrivateCategory',
        component: () =>
          import('./views/persist/PersistPrivateCategoryView.vue'),
        props: true,
      },

      {
        path: '/repository/list',
        component: () => import('./views/list/ListRepositoryView.vue'),
      },
      {
        path: '/repository/new',
        component: () => import('./views/persist/PersistRepositoryView.vue'),
      },
      {
        path: '/repository/edit/:id',
        name: 'editRepository',
        component: () => import('./views/persist/PersistRepositoryView.vue'),
        props: true,
      },

      {
        path: '/organization/list',
        component: () => import('./views/list/ListOrganizationView.vue'),
      },
      {
        path: '/organization/new',
        component: () => import('./views/persist/PersistOrganizationView.vue'),
      },
      {
        path: '/organization/edit/:id',
        name: 'editOrganization',
        component: () => import('./views/persist/PersistOrganizationView.vue'),
        props: true,
      },

      {
        path: '/platform-type/list',
        component: () => import('./views/list/ListPlatformTypeView.vue'),
      },
      {
        path: '/platform-type/new',
        component: () => import('./views/persist/PersistPlatformTypeView.vue'),
      },
      {
        path: '/platform-type/edit/:id',
        name: 'editPlatformType',
        component: () => import('./views/persist/PersistPlatformTypeView.vue'),
        props: true,
      },
      {
        path: '/exchange/list',
        component: () => import('./views/list/ListExchangeView.vue'),
      },
      {
        path: '/exchange/new',
        component: () => import('./views/persist/PersistExchangeView.vue'),
      },
      {
        path: '/exchange/edit/:id',
        name: 'editExchange',
        component: () => import('./views/persist/PersistExchangeView.vue'),
        props: true,
      },
      {
        path: '/nftCollection/list',
        component: () => import('./views/list/ListNftView.vue'),
      },
    ],
  },
  {path: '*', redirect: '/'},
]

module.exports = {routes}

async function getDappSlugs() {
  try {
    const {data} = await axios.get(
      `${process.env.VUE_APP_API_URL}client/dapp?isDapp=true`
    )
    return data.items.map(dapp => dapp.slug)
  } catch (e) {
    console.error("Couldn't fetch dapp slugs", e)
    return []
  }
}

async function getAppSlugs() {
  try {
    const {data} = await axios.get(
      `${process.env.VUE_APP_API_URL}client/app?isDapp=true`
    )
    return data.items.map(app => app.slug)
  } catch (e) {
    console.error("Couldn't fetch app slugs", e)
    return []
  }
}
