import {SwiperOptions} from 'swiper'
import {$} from '@/facade'

export class SwiperGlobalOptions implements SwiperOptions {
  readonly slidesPerView = $.env.SWIPER_PER_PAGE_MOBILE
  readonly slidesPerGroup = $.env.SWIPER_PER_PAGE_MOBILE
  readonly spaceBetween = 32
  readonly grabCursor = true

  readonly breakpoints = {
    // when device is tablet
    [$.env.SMALL_SCREEN]: {
      slidesPerView: $.env.SWIPER_PER_PAGE_TABLET,
      slidesPerGroup: $.env.SWIPER_PER_PAGE_TABLET,
    },
    // when device is desktop
    [$.env.LARGE_SCREEN]: {
      slidesPerView: $.env.SWIPER_PER_PAGE_DESKTOP,
      slidesPerGroup: $.env.SWIPER_PER_PAGE_DESKTOP,
    },
  }
}
