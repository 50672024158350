/**
 * @file
 * Router Configuration
 * Used in library: vue-router
 *
 * Use this file to register the App routes
 * See https://router.vuejs.org/guide/#javascript
 * This configuration will be set in @/app/Setup.ts
 */

import {RouterOptions} from 'vue-router/types/router'
import {routes} from '@/routes'

/**
 * VUE Router Configuration
 */
export class RouterConfig implements RouterOptions {
  readonly mode = 'history'

  /** Do not include path strings in noCachePathRoutes
   *  for the following expressions:
   *  - edit/:id or edit/:id/
   *  - new
   *  - list
   */
  readonly noCachePathRoutes = [
    '/dapp/',
    '/blockchain-version/',
    '/banner/',
    '/organization/',
    '/repository/',
    '/contract/',
    '/contract/nep17/',
  ]

  readonly routes = routes

  isNoCacheRoute(path: string) {
    // Clean from path: 'edit/:id' or 'edit/:id/' or 'new' or 'list'
    const newPath = path.replace(/(edit\/(\w|-)*?\/|new|list)/g, '')
    return this.noCachePathRoutes.findIndex(x => newPath.includes(x)) >= 0
  }
}
