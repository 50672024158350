import {tx} from '@cityofzion/neon-core'
import {$} from '@/facade'
import {
  GenericInvocationBuilder,
  InvocationBuilderResult,
  SwapNeoUsingNeoProxyArgs,
} from '@/model/wallets/invocationsBuilder/GenericInvocationBuilder'
import {
  SwapServiceSwapToReceiveArgs,
  SwapServiceSwapToUseArgs,
} from '@cityofzion/blockchain-service'
import {
  BSNeo3NetworkId,
  FlamingoSwapInvocationBuilderNeo3,
} from '@cityofzion/bs-neo3'
import {EnvHelper} from '@/helpers/EnvHelper'
import {NeoHelper} from '@/helpers/NeoHelper'
import {ContractInvocationMulti} from '@cityofzion/wallet-connect-sdk-core'

export class NeonWalletInvocationBuilder implements GenericInvocationBuilder {
  claimGasInvocation(address: string): InvocationBuilderResult {
    const scriptHash = NeoHelper.getScriptHashFromAddress(address)

    const contractInvocationMulti: ContractInvocationMulti = {
      invocations: [
        {
          scriptHash: EnvHelper.VUE_APP_NEO_SCRIPT_HASH,
          operation: 'transfer',
          args: [
            {
              type: 'Hash160',
              value: scriptHash,
            },
            {
              type: 'Hash160',
              value: scriptHash,
            },
            {
              type: 'Integer',
              value: '0',
            },
            {
              type: 'Any',
              value: null,
            },
          ],
        },
      ],
      signers: [
        {
          scopes: tx.WitnessScope.CalledByEntry,
        },
      ],
    }

    return contractInvocationMulti
  }

  swapInvocation(
    data:
      | SwapServiceSwapToReceiveArgs<BSNeo3NetworkId>
      | SwapServiceSwapToUseArgs<BSNeo3NetworkId>
  ): InvocationBuilderResult {
    return FlamingoSwapInvocationBuilderNeo3.swapInvocation(data)
  }

  voteInvocation(candidatePublicKey: string): InvocationBuilderResult {
    return {
      invocations: [
        {
          operation: 'vote',
          scriptHash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
          args: [
            {
              type: 'Hash160',
              value: $.walletAdapter.address!,
            },
            {
              type: 'PublicKey',
              value: candidatePublicKey,
            },
          ],
        },
      ],
      signers: [
        {
          scopes: tx.WitnessScope.CalledByEntry,
        },
      ],
    }
  }

  swapNeoUsingNeoProxyInvocation({
    address,
    neoAmountIn,
    gasAmountOutMin,
  }: SwapNeoUsingNeoProxyArgs): InvocationBuilderResult {
    return {
      invocations: [
        {
          scriptHash: EnvHelper.VUE_APP_NEO_PROXY_SCRIPT_HASH,
          operation: 'swapNeo',
          args: [
            {
              type: 'Hash160',
              value: address,
            },
            {
              type: 'Integer',
              value: neoAmountIn,
            },
            {
              type: 'Integer',
              value: gasAmountOutMin,
            },
          ],
        },
      ],
      /**
       * CU-86drvn85a: Investigate what is NeoLine's broadcastOverride and how to where to implement it on NeonDappkit
       *
       * Issue Comment: "The error was the amount of network fee  we were using. The network fee  can be wrong when you end up calling a verify.
       *                 To circumvent this problem you can add a hardcoded networkFeeOverride: 10000000(0.1 gas) on the ContractInvocationMulti."
       */
      networkFeeOverride: 10000000,
      signers: [
        {
          account: EnvHelper.VUE_APP_NEO_PROXY_SCRIPT_HASH,
          scopes: tx.WitnessScope.Global,
        },
        {
          account: NeoHelper.getScriptHashFromAddress(address),
          scopes: tx.WitnessScope.Global,
          allowedContracts: [
            EnvHelper.VUE_APP_NEO_PROXY_SCRIPT_HASH,
            NeoHelper.getScriptHashFromAddress(address),
          ],
        },
      ],
    }
  }
}
