import {classToClass, ClassTransformOptions} from 'class-transformer'
import {sha256} from 'js-sha256'
const shortid = require('shortid')

export abstract class UtilsHelper {
  static sha256(hash?: string | null) {
    if (hash) {
      return sha256(hash)
    }

    return null
  }

  static uid(prefix?: string, suffix?: string) {
    return `${prefix || ''}${shortid.generate()}${suffix || ''}`
  }

  static sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  static clone<T>(fromEntity: T, options?: ClassTransformOptions): T {
    return classToClass(fromEntity, options)
  }

  static isiOS() {
    return Boolean(/iPad|iPhone|iPod/.test(navigator.userAgent))
  }

  static isAndroid() {
    return Boolean(/Android/i.test(navigator.userAgent))
  }

  static isSafari() {
    return Boolean(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
  }

  static copyToClipboard(text: string) {
    const el = document.createElement('textarea')
    el.value = text
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  static base64ToHex(str: string) {
    const raw = atob(str)

    let result = ''

    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16)
      result += hex.length === 2 ? hex : '0' + hex
    }

    return result.toUpperCase()
  }

  static weeklyAmount(dailyAmount: number) {
    return dailyAmount * 7
  }

  static monthlyAmount(dailyAmount: number) {
    return dailyAmount * 30
  }
  static yearlyAmount(dailyAmount: number) {
    return dailyAmount * 365
  }

  static async debounce(func: () => void, delay: number): Promise<void> {
    await new Promise(resolve => setTimeout(resolve, delay))
    return func()
  }
}
