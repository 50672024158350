import {RootState, WalletAdapterState} from '@/types/store'
import {walletAdapter, store} from '@/store'
import {AccessorWrapper} from '@simpli/vuex-typescript'
import {WalletPlatform} from '@/enums/WalletPlatform'
import {InvokeParams} from '@/model/wallets/types/WalletTypes'

export const wrapper = new AccessorWrapper<WalletAdapterState, RootState>(
  'walletAdapter'
)

export abstract class WalletAdapter {
  static $accessors = wrapper.accessors

  // Getters
  static get address() {
    return WalletAdapter.$accessors.read(walletAdapter.getters.address)(store)
  }

  static get uri() {
    return WalletAdapter.$accessors.read(walletAdapter.getters.uri)(store)
  }

  static get connectedWalletPlatform() {
    return WalletAdapter.$accessors.read(
      walletAdapter.getters.connectedWalletPlatform
    )(store)
  }

  // Actions
  static init() {
    return WalletAdapter.$accessors.dispatch(walletAdapter.actions.init)(store)
  }

  static connect(walletPlatform: WalletPlatform) {
    return WalletAdapter.$accessors.dispatch(walletAdapter.actions.connect)(
      store,
      walletPlatform
    )
  }

  static disconnect() {
    return WalletAdapter.$accessors.dispatch(walletAdapter.actions.disconnect)(
      store
    )
  }

  static canUseWallet(walletPlatform: WalletPlatform) {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.canUseWallet
    )(store, walletPlatform)
  }

  static invoke(invokeParams: InvokeParams) {
    return WalletAdapter.$accessors.dispatch(walletAdapter.actions.invoke)(
      store,
      invokeParams
    )
  }

  static async executeWithConnectedWallet(
    actionToDoAfterConnect: () => Promise<void> | void
  ) {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.executeWithConnectedWallet
    )(store, actionToDoAfterConnect)
  }
}
