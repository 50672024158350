import {ActionContext, Module} from 'vuex'
import {AccessorHandler} from '@simpli/vuex-typescript'
import {$} from '@/facade'
import {AuthState, RootState} from '@/types/store'
import {AuthRequest} from '@/model/request/AuthRequest'
import {AuthResponse} from '@/model/response/AuthResponse'
import {Global} from '@/model/resource/Global'

export type AuthContext = ActionContext<AuthState, RootState>

@AccessorHandler
export class AuthModule implements Module<AuthState, RootState> {
  namespaced = true

  state: AuthState = {
    token: null,
    user: null,
    cachePath: null,
    global: new Global(),
  }

  getters = {
    isLogged: (state: AuthState) => Boolean(state.token),
    token: (state: AuthState) => state.token,
    user: (state: AuthState) => state.user,
    cachePath: (state: AuthState) => state.cachePath,
    global: (state: AuthState) => state.global,
  }

  actions = {
    /**
     * Sign in account
     */
    async signIn(context: AuthContext, request: AuthRequest) {
      const authResponse = await request.signIn()

      if (authResponse.token) localStorage.setItem('token', authResponse.token)

      context.commit('POPULATE_TOKEN')
      context.commit('POPULATE', authResponse)

      const uri =
        context.getters.cachePath && $.route.name !== 'signIn'
          ? context.getters.cachePath
          : '/dashboard'

      $.toast.info('system.info.welcome')
      await $.nav.push(uri)

      context.commit('SET_CACHE_PATH', null)

      return authResponse
    },

    /**
     * Verifies authorization and refresh user info.
     */
    async authenticate(context: AuthContext) {
      context.commit('POPULATE_TOKEN')

      let authResponse
      if (context.getters.token) {
        try {
          authResponse = await AuthRequest.authenticate()
        } catch {
          context.commit('FORGET')
        }
      }

      const global = await Global.getGlobal()
      context.commit('POPULATE_GLOBAL_SYSTEM_PREFERENCES', global)

      if (!authResponse) {
        return
      }

      context.commit('POPULATE', authResponse)

      return authResponse
    },

    /**
     * Sign out account
     */
    async signOut(context: AuthContext) {
      context.commit('FORGET')
    },

    async loadGlobal(context: AuthContext) {
      const global = await Global.getGlobal()
      context.commit('POPULATE_GLOBAL_SYSTEM_PREFERENCES', global)
    },
  }

  mutations = {
    POPULATE_TOKEN(state: AuthState) {
      state.token = localStorage.getItem('token') ?? null
    },

    POPULATE(state: AuthState, response: AuthResponse) {
      state.user = response.user
    },

    POPULATE_GLOBAL_SYSTEM_PREFERENCES(state: AuthState, global: Global) {
      state.global = global
    },

    FORGET(state: AuthState) {
      state.token = null
      state.user = null

      localStorage.removeItem('token')
    },
  }
}
