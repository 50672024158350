import {WalletPlatform} from '@/enums/WalletPlatform'

export const SdkNotInitializedError = (wallet: WalletPlatform): Error => {
  const walletString: string = String(wallet).trim()

  const formattedWallet: string = walletString
    ? walletString.charAt(0).toUpperCase() + walletString.slice(1).toLowerCase()
    : 'WalletSDK'

  return new Error(`${formattedWallet} not initialized`)
}

export const UnimplementedWallet: Error = new Error('Unimplemented Wallet')

export const WalletNotConnected: Error = new Error('Wallet not connected')

export const InvocationDoesNotExists: Error = new Error(
  'Invocation does not exists'
)

export const BadInvocationRequest: Error = new Error('Bad Invocation Request')

export const RejectedByUser: Error = new Error('Rejected by the user')
