import {Vue} from 'vue-property-decorator'
import {SweetAlertIcon, SweetAlertOptions, SweetAlertResult} from 'sweetalert2'
import {$} from '@/facade'
import {IResource} from '@simpli/resource-collection'

export abstract class DialogHelper {
  static swalConfirm(options: SweetAlertOptions): Promise<SweetAlertResult> {
    return new Promise((resolve, reject) => {
      Vue.swal(options).then(result => {
        if (result.isConfirmed) {
          resolve(result)
        } else if (result.isDismissed) {
          reject('Cancelled operation')
        }
      })
    })
  }

  static async confirm(
    resource: IResource | null,
    i18n: string,
    icon: SweetAlertIcon = 'warning'
  ) {
    await this.swalConfirm({
      title: $.t(i18n) as string,
      text: resource?.$tag,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: $.t('system.question.yes') as string,
    })
  }

  static async confirmResync(
    text: string | null,
    i18n: string,
    icon: SweetAlertIcon = 'warning'
  ) {
    await this.swalConfirm({
      title: $.t(i18n) as string,
      text: $.t('system.question.resyncTxDataInformation') as string,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: $.t('system.question.yes') as string,
    })
  }

  static async remove(
    resource: IResource | null = null,
    msg: string | null = null
  ) {
    await this.confirm(resource, msg || 'system.question.confirmRemove')
  }

  static async softDelete(
    resource: IResource | null = null,
    msg: string | null = null
  ) {
    await this.confirm(resource, msg || 'system.question.ignoreContract')
  }

  static async recoverContract(
    resource: IResource | null = null,
    msg: string | null = null
  ) {
    await this.confirm(resource, msg || 'system.question.recoverContract')
  }

  static async resyncTxData(
    text: string | null = null,
    msg: string | null = null
  ) {
    await this.confirmResync(text, msg || 'system.question.resyncTxData')
  }
}
