import {GenericWalletAdapter} from './GenericWalletAdapter'
import WalletConnectSDK, {
  ContractInvocationMulti,
  NetworkType,
} from '@cityofzion/wallet-connect-sdk-core'
import SignClient from '@walletconnect/sign-client'
import {WalletConnectConfig} from '@/config/WalletConnectConfig'
import {WalletAdapterContext} from '@/store/modules/WalletAdapterModule'
import {WalletMutation} from '@/enums/WalletMutation'
import {WalletPlatform} from '@/enums/WalletPlatform'
import {PopulateSdkStatusParams} from '@/model/wallets/types/WalletTypes'
import {SdkNotInitializedError} from '@/model/wallets/types/WalletErrors'
import {InvocationBuilderResult} from '@/model/wallets/invocationsBuilder/GenericInvocationBuilder'

export class NeonWalletAdapter implements GenericWalletAdapter {
  private sdk: WalletConnectSDK | null = null
  private client: SignClient | null = null

  async init(context: WalletAdapterContext) {
    try {
      this.client = await SignClient.init(WalletConnectConfig.options)
      this.sdk = new WalletConnectSDK(this.client as any)

      this.sdk.loadSession()

      if (this.sdk.isConnected()) {
        context.commit(
          WalletMutation.POPULATE_CONNECTED_WALLET_TYPE,
          WalletPlatform.NEON
        )
        context.commit(
          WalletMutation.POPULATE_ADDRESS,
          this.sdk.getAccountAddress()
        )
      }

      this.client.on('session_delete', () => {
        context.commit(WalletMutation.POPULATE_CONNECTED_WALLET_TYPE, null)
        context.commit(WalletMutation.POPULATE_ADDRESS, null)
        context.commit(WalletMutation.POPULATE_LAST_WALLET_CONNECTED, null)
      })

      context.commit(WalletMutation.POPULATE_SDK_STATUS, {
        wallet: WalletPlatform.NEON,
        status: 'started',
      } as PopulateSdkStatusParams)
    } catch (error) {
      context.commit(WalletMutation.POPULATE_SDK_STATUS, {
        wallet: WalletPlatform.NEON,
        status: 'error',
      } as PopulateSdkStatusParams)
    }
  }

  async connect(context: WalletAdapterContext) {
    if (!this.sdk) throw SdkNotInitializedError(WalletPlatform.NEON)

    const {approval, uri} = await this.sdk.createConnection(
      WalletConnectConfig.network as NetworkType,
      WalletConnectConfig.methods
    )
    context.commit(WalletMutation.POPULATE_URI, uri ?? '')

    await approval()
    this.sdk.loadSession()

    context.commit(
      WalletMutation.POPULATE_ADDRESS,
      this.sdk.getAccountAddress()
    )
  }

  async disconnect() {
    if (!this.sdk) throw SdkNotInitializedError(WalletPlatform.NEON)
    await this.sdk.disconnect()
  }

  isInstalled(): boolean {
    return this.sdk !== null && this.client !== null
  }

  async invoke(multiInvoke: InvocationBuilderResult) {
    if (!this.sdk) throw SdkNotInitializedError(WalletPlatform.NEON)

    return await this.sdk.invokeFunction(
      (multiInvoke as unknown) as ContractInvocationMulti
    )
  }
}
